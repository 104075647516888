import { images, productInnerBanner, specialityVideo, tubebg } from "../assets";
import _ from "lodash";
export const SuperCategoriesArr = [
  // {
  //   id: 1,
  //   type: "image",
  //   backgroundImg: productInnerBanner,
  //   title: "Cast, Extruded & Drawn Semis",
  //   slug: "cast-extruded-and-drawn-semis",
  //   description:
  //     "Multimetals offers a diverse range of casts /7 semis including copper, copper nickel, nickel silver, and brass rods, brass hollows, Aluminium Bronze Rods, and phosphorus bronze rods. Our state-of-the-art manufacturing processes ensure precision and consistency in the production of these products, which find extensive applications in industries such as automotive, electrical, aerospace, and more. With a commitment to excellence, we deliver products that meet the highest quality standards and exhibit exceptional strength, durability, and performance. Trust Multimetals for reliable and top-quality cast, extruded, and drawn semis that meet your specific requirements with unmatched precision and reliability.",
  // },
];
export const ProductCategoriesArr = [
  {
    id: 1,
    type: "image",
    backgroundImg: tubebg,
    title: "Tube & Pipes",
    slug: "tube-and-pipes",
    description:
      "At Multimetals, we specialize in the manufacturing of high-quality tubes and pipes that cater to a wide range of industries. Our tubes and pipes are crafted with precision and adhere to the highest standards of quality and performance. With a commitment to innovation and advanced manufacturing techniques, we offer a diverse range of tubes and pipes that meet the unique requirements of our customers and deliver exceptional performance in various applications.",
  },
  {
    id: 2,
    type: "video",
    backgroundImg: specialityVideo,
    title: "Speciality & Finned Tubes",
    slug: "speciality-and-finned-tubes",
    description:
      "Multimetals takes pride in producing specialized and finned tubes that are tailored to meet the specific needs of various industries. Our specialty and finned tubes offer enhanced heat transfer capabilities and superior performance in applications such as heat exchangers, air conditioning systems, and refrigeration units. With our advanced manufacturing processes and attention to detail, we deliver high-quality and reliable tubes that excel in efficiency and durability. Whether it's for commercial, industrial, or residential purposes, our specialty and finned tubes are designed to optimize performance and deliver exceptional results.",
  },
  {
    id: 3,
    type: "image",
    backgroundImg: productInnerBanner,
    title: "Cast, Extruded & Drawn Semis",
    slug: "cast-extruded-and-drawn-semis",
    description:
      // "Multimetals offers a diverse range of casts /7 semis including copper, copper nickel, nickel silver, and brass rods, brass hollows, Aluminium Bronze Rods, and phosphorus bronze rods. Our state-of-the-art manufacturing processes ensure precision and consistency in the production of these products, which find extensive applications in industries such as automotive, electrical, aerospace, and more. With a commitment to excellence, we deliver products that meet the highest quality standards and exhibit exceptional strength, durability, and performance. Trust Multimetals for reliable and top-quality cast, extruded, and drawn semis that meet your specific requirements with unmatched precision and reliability.",
      "Multimetals offers a diverse range of casts /7 semis including copper, copper nickel, nickel silver, and brass rods, brass hollows, Aluminium Bronze Rods, and phosphorus bronze rods. Our state-of-the-art manufacturing processes ensure precision and consistency in the production of these products, which find extensive applications in industries such as automotive, electrical, aerospace, and more. With a commitment to excellence, we deliver products that meet the highest quality standards and exhibit exceptional strength, durability, and performance.",
  },
  {
    id: 4,
    super_category_id: 1,
    type: "image",
    backgroundImg: productInnerBanner,
    listingImage: images.copper_liting.image,
    application_area:
      "High capacity Power Plants, Ship Building & Ship Repairs,Heat Exchangers and Condensers, Distiller Tubes, Evaporators, Ferrules",
    title: "Rods",
    slug: "rods",
    description:
      "Rods Multimetals offers a diverse range of casts /7 semis including copper, copper nickel, nickel silver, and brass rods, brass hollows, Aluminium Bronze Rods, and phosphorus bronze rods. Our state-of-the-art manufacturing processes ensure precision and consistency in the production of these products, which find extensive applications in industries such as automotive, electrical, aerospace, and more. With a commitment to excellence, we deliver products that meet the highest quality standards and exhibit exceptional strength, durability, and performance. Trust Multimetals for reliable and top-quality cast, extruded, and drawn semis that meet your specific requirements with unmatched precision and reliability.",
  },
];

export const productsArr = [
  {
    id: 1,
    category_id: 1,
    title: "Copper",
    slug: "copper",
    application_area:
      "High capacity Power Plants, Ship Building & Ship Repairs,Heat Exchangers and Condensers, Distiller Tubes, Evaporators, Ferrules",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,
    childs: [
      {
        id: 2,
        category_id: 1,
        title: "ETP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["BS - C101", "ASTM - C11000", "IS - CU-ETP"],
        image: images.copper_etp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Power Plants</li> <li>> Automobile Industry</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li> <li>> Chillers</li> <li>> Sanitry & Plumbing</li> <li>> Air Conditioning & Refrigeration</li> <li>> Electrical Application in Switch Gear Industries</li></ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H<sub>2</sub>S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 170 mm", "WT - 0.5 mm to 12 mm"],
      },
      {
        id: 3,
        category_id: 1,
        title: "FRTP Copper",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["IS - CU-FRTP-1", "IS - FRTP-2"],
        image: images.copper_frtp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Electrical Application</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H<sub>2</sub>S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 170 mm", "WT - 0.5 mm to 12 mm"],
      },
      {
        id: 4,
        category_id: 1,
        title: "DHP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C12200",
          "BS - C106",
          "EN - DHP",
          "JIS - C12200",
          "ASME - C12200",
          "MIL - C12200",
          "IS - CU DHP",
          "DSTAN - CU DHP (CW024A)",
          "BS EN - CU DHP",
          "DEF STAN - CU DHP",
          "EN - CUDHP - CW024A",
          "DIN EN - CU- DHP- CW024A",
          "DEF + NES + EN - CU DHP",
          "GHOST - CU DHP",
          "NES - DHP CU - CW024A",
          "JSS - GR - 1",
          "DIN - SF CU F25",
        ],
        image: images.copper_dhp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Power Plants</li> <li>> Oil & Gas (offshore) Industry</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers </li> <li>> Evaporator</li> <li>> Chillers</li> <li>> Sanitry & Plumbing</li> <li>> Air Conditioning & Refrigeration</li></ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H<sub>2</sub>S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 170 mm", "WT - 0.5 mm to 12 mm"],
      },
      {
        id: 5,
        category_id: 1,
        title: "DLP Copper",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASME - C12000", "JIS - C1201T", "IS - CU-DLP"],
        image: images.copper_dlp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Power Plants</li> <li>> Oil & Gas (offshore) Industry</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li> <li>> Chillers</li> <li>> Sanitry & Plumbing</li> <li>> Air Conditioning & Refrigeration</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H<sub>2</sub>S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 170 mm", "WT - 0.5 mm to 12 mm"],
      },
    ],
  },
  {
    id: 2,
    category_id: 1,
    title: "Copper Nickel",
    slug: "copper-nickel",
    application_area:
      "Chillers, Heat Exchangers & Condensers, Power Plants, Refineries, Desalination Plants, Ship Building & Ship Repairs, Defense",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_nickel_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,
    childs: [
      {
        id: 2,
        category_id: 2,
        title: "95/5 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["GOST - CUNI95/5", "ASTM - C70400"],
        image: images.copper_nickel95.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Heat Exchangers, Condensers</li> <li>> Chillers</li> <li>> Air Conditioning & Refrigeration</li></ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel95_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 220 mm", "WT - 0.5 mm to 8 mm"],
      },
      {
        id: 3,
        category_id: 2,
        title: "90/10 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "EEMUA + ASTM - 7060X & C70600",
          "EEMUA + BS - 7060X & CN102",
          "EEMUA + ASTM - 7060X, C70600, C70620",
          "DEF STAN - CN 102",
          "NES - 90/10 CUNI",
          "ASME & ASTM - C70600",
          "ASME - C70600",
          "ASTM - C70600",
          "AES - C70600",
          "MIL - C70600",
          "ASME + EN  - C70600 & CUNI10FE1MN",
          "ASME SB + EN + PED + AD - C70600+CUNI10FE1MN",
          "JIS - C7060T",
          " JIS - C7060TS",
          "BS - CN102",
          "NES - CN 102",
          "IS - CUNI10FE",
          "IS - CUNI10FE1",
          "DIN - CUNI10FE1.6MN",
        ],
        image: images.copper_nickel90.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Power Plants</li> <li>> Oil & Gas (offshore) Industry</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li> <li>> Chillers</li></ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel90_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts from 1500 to 3000 ppm </li> <li>> Suspended solids more than 25 ppm </li> <li>> H<sub>2</sub>S or Ammonia less than 1 ppm </li></ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 4.76 mm to 220 mm", "WT - 0.5 mm to 8 mm"],
      },
      {
        id: 4,
        category_id: 2,
        title: "70/30 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "NES - 70/30 COPPER NICKEL",
          "DEF STQN - 70/30 COPPER NICKEL",
          "ASTM - C71500",
          "MIL - C71500",
          "ASME - C71500",
          "EN + AD + PED - CUNI30MN1FE(CW354H)",
          " BS - CN107",
          "IS - CUNI30MN1FE",
          "GOST - CUNI30FE1MN",
        ],
        image: images.copper_nickel70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Power Plants</li> <li>> Oil & Gas (offshore) Industry</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel70_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts more than 10,000 ppm </li> <li>> Presence of abrasive suspended solids </li> <li>> Polluted with H<sub>2</sub>S and Ammonia </li></ul>",
        have_child: false,
        parent_id: 1,
        size: ["OD - 6.35 mm to 73 mm", "WT - 0.7 mm to 8 mm"],
      },
      {
        id: 5,
        category_id: 2,
        title: "CuNifer",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASME - C71640", "ASTM - C71640"],
        image: images.copper_nickel_cunifer.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Power Plants</li> <li>> Heat Exchangers, Condensers</li>  </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel95_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 6.35 mm to 73 mm", "WT - 0.7 mm to 8 mm"],
      },
    ],
  },
  {
    id: 3,
    category_id: 1,
    title: "Plain Brasses",
    slug: "plain-brasses",
    application_area:
      "Sugar Industry, Plumbing, Furniture, General Engineering, Ammunition, Bore Hole Tubes, Ammunition, Architectural Grill",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brasses_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,
    childs: [
      {
        id: 1,
        category_id: 3,
        title: "90/10 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C 22000",
          "EN - CUZN10 (CW501L)",
          "MIL - CUZN10 N220 (2.0230)",
          "JSS - GILDING METAL GR-II",
          "BS - CZ-112",
        ],
        image: images.brasses_tube90.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc: "<ul> <li>> Defence Application</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 175 mm", "WT - 0.8 mm to 12 mm"],
      },
      {
        id: 2,
        category_id: 3,
        title: "Red Brass 85/15",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C23000", "EN - CUZN15 (CW502L)"],
        image: images.brasses_tube_red85.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 175 mm", "WT - 0.8 mm to 12 mm"],
      },
      {
        id: 3,
        category_id: 3,
        title: "70/30 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "JSS - 70/30 BRASS",
          "ASTM - C26000",
          "EN - CUZN30AS (CW707R)",
          "IS - CUZN30AS",
          "BS - CZ-126",
        ],
        image: images.brasses_tube70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sugar & Chemical Industry</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.brass_tube70_specsheet.image,
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm", "WT - 0.8 mm to 5 mm"],
      },
      {
        id: 4,
        category_id: 3,
        title: "65/35 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["JIS - C2700", "ASTM - C27000"],
        image: images.brasses_tube65.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Heat Exchangers, Condensers</li> <li>> Sanitry & Plumbing</li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 170 mm", "WT - 0.8 mm to 12 mm"],
      },
      {
        id: 5,
        category_id: 3,
        title: "63/37 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS - CZ108",
          "ASTM - C27200",
          "EN - CUZN37 (CW508L)",
          "IS - CUZN37",
        ],
        image: images.brasses_tube63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sugar & Chemical Industry</li> <li>> Sanitry & Plumbing</li> </ul>",
        // spec_two_title: "Specsheets",
        spec_two_title: "",
        spec_two_desc: "",
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm", "WT - 0.8 mm to 5 mm"],
      },
      {
        id: 6,
        category_id: 3,
        title: "60/40 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C28000",
          "ASTM - C28500 ",
          "EN - CUZN40 (CW509L)",
          "IS - 60/40 BRASS",
          "ASME - C28000",
        ],
        image: images.brasses_tube60.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc: "<ul> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 170 mm", "WT - 1 mm to 12 mm"],
      },
      {
        id: 7,
        category_id: 3,
        title: "Naval Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C46400",
          "IS - GRADE 1",
          "BS - CZ112 ",
          "EN - CUZN39SN1 (CW719R)",
        ],
        image: images.brasses_tube_naval.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Bearing & Bushing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 20 mm to 140 mm", "WT - 1.25 mm to 8 mm"],
      },
      {
        id: 8,
        category_id: 3,
        title: "80/20 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["EN - CUZN20 (CW503)", "ASTM - C24000", "IS - CUZN20"],
        image: images.brasses_tube80.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc: "<ul> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 175 mm", "WT - 0.8 mm to 12 mm"],
      },
      {
        id: 9,
        category_id: 3,
        title: "95/5 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C21000", "EN - CW500L (CUZN5)"],
        image: images.brass_rod95.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 175 mm", "WT - 0.8 mm to 12 mm"],
      },
      // {
      //   id: 10,
      //   category_id: 3,
      //   title: "Admiralty Brass",
      //   // slug: "",
      //   // description:
      //   //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
      //   standards: [
      //     "ASME - C44300",
      //     "ASTM - C44300",
      //     "DIN - CUZN28SN1",
      //     "EN - CUZN28SN1AS (CW706R)",
      //     "IS - CUZN28SN1AS",
      //     "BS - CZ111",
      //   ],
      //   image: images.ad_brass_hollow.image,
      //   spec_one_title: "Product Applications areas",
      //   spec_one_desc:
      //     "<ul> <li>> Power Plants</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li></ul>",
      //   spec_two_title: "",
      //   spec_two_desc: "",
      //   spec_three_title: "",
      //   spec_three_desc: "",
      //   have_child: false,
      //   parent_id: 1,
      //   size: ["OD - 8 mm to 80 mm", "WT - 0.8 mm to 5 mm"],
      // },
    ],
  },
  {
    id: 4,
    category_id: 1,
    title: "Admiralty Brass",
    slug: "admiralty-brass",
    application_area:
      "Power Plants, Desalination Plants, Heat Exchangers, Condensers, Evaporator",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "ASME - C44300",
      "ASTM - C44300",
      "DIN - CUZN28SN1",
      "EN - CUZN28SN1AS (CW706R",
      "IS - CUZN28SN1AS",
      "BS - CZ111",
    ],
    image: images.ad_brass.image,
    listingImage: images.ad_brasses_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Power Plants</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li></ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.admiralty_brass_specsheet.image,
    // spec_two_desc: "DYNAMIC",
    spec_three_title: "Guide to selection of tube materials",
    spec_three_desc:
      "<ul> <li>> Dissolved salts less than 2000 ppm </li> <li>> Chloride less than 20 ppm </li> <li>> H<sub>2</sub>S and Ammonia is less than 1 ppm </li> <li>> Suspended solids less than 25 ppm </li> <li>> For temperature more than 200 degree C and less than 450 degree C. </li> </ul>",
    have_child: false,
    parent_id: null,
    childs: [],
    size: ["OD - 8 mm to 80 mm", "WT - 0.8 mm to 5 mm"],
  },
  {
    id: 5,
    category_id: 1,
    title: "Aluminium Brass",
    slug: "aluminium-brass",
    application_area:
      "Power Plants, Desalination Plants, Ship Building & Ship Repairs, eat Exchangers, Condensers, Evaporator",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "JIS - C6870",
      "JIS - C6872",
      "ASME - C68700",
      "ASTM - C68700",
      "NFA - CUZN22AL2",
      "DIN - CUZN20AL2",
      "EN - CUZN20AL2AS (CW702R)",
      "BS - CZ110",
      "IS - CUZN20AL2AS",
    ],
    image: images.al_brass.image,
    listingImage: images.al_brasses_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Power Plants</li> <li>> Desalination Plants</li> <li>> Heat Exchangers, Condensers</li> <li>> Evaporator</li></ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.aluminium_brass_specsheet.image,
    // spec_two_desc: "DYNAMIC",
    spec_three_title: "Guide to selection of tube materials",
    spec_three_desc:
      "<ul> <li>> Dissolved salts more than 2000 ppm </li> <li>> Chloride less than 20 ppm </li> <li>> H<sub>2</sub>S and Ammonia is less than 1 ppm </li> <li>> Suspended solids less than 25 ppm </li> <li>> For temperature more than 200 degree C and less than 450 degree C </li> </ul>",
    have_child: false,
    parent_id: null,
    childs: [],
    size: ["OD - 8 mm to 80 mm", "WT - 0.8 mm to 5 mm"],
  },
  {
    id: 6,
    category_id: 1,
    title: "Copper Arsenic Aluminium Bronze",
    slug: "copper-aresenic-aluminium-bronze",
    application_area:
      "Heat Exchangers in Chemical Industry, Desalination Plants, Refineries, Ship Building & Ship Repairs.",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["EN - CUAL5AS(CW300G)", "ASTM - C60800"],
    image: images.al_bronze.image,
    listingImage: images.al_bronze_listing.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc: "<ul> <li>> Fertilizers & Potash Industries</li></ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.aluminium_bronze_specsheet.image,
    // spec_two_desc: "DYNAMIC",
    spec_three_title: "",
    spec_three_desc: "",
    have_child: false,
    parent_id: null,
    childs: [],
    size: ["OD - 20 mm to 73 mm", "WT - 1.5 mm to 5 mm"],
  },
  {
    id: 7,
    category_id: 2,
    // title: "Finned tube as per ASTM B 359",
    title: "Finned tube",
    slug: "finned-tubes",
    application_area:
      "Heat Exchangers, Condensers and Coolers, Condenser and Heat Exchange Unit",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 359"],
    grade: true,
    image: images.finned_tube.image,
    listingImage: images.finned_tube_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Heat Exchanger </li> <li>> Condensers and Coolers </li> <li>> Condenser and Heat Exchange Unit </li> </ul>",
    spec_two_title: "Specsheets",
    // spec_two_desc: images.finned_tube_specsheet.image,
    spec_two_desc: images.finned_tube_specsheet.image,
    // spec_two_desc: "DYNAMIC",
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> Copper </li> <li>> Copper Nickel 95/5, 90/10, 70/30 </li> <li>> Admiralty Brass </li> <li>> Aluminium Brass </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 8,
    category_id: 2,
    title: "U Bend Tubes",
    slug: "u-bend-tubes",
    application_area: "Condensers, Evaporators, Radiators and Heat Exchangers",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 395"],
    grade: true,
    image: images.u_bend_tube.image,
    listingImage: images.u_bend_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Condensers </li> <li>> Evaporators </li> <li>> Radiators and Heat Exchangers </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> Copper </li> <li>> Copper Nickel 95/5, 90/10, 70/30 </li> <li>> Admiralty Brass </li> <li>> Aluminium Brass </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 9,
    category_id: 2,
    title: "Machined Components",
    slug: "machined-components",
    application_area:
      "Driving Band Blanks for Defense Applications, Marine Industry, Synchronizer Rings for Automobile, Bushes",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "ASTM B135 & C22000",
      "ASTM B75 & C12200",
      "ASTM B466 & C70400",
      "BS 2871 & C106",
      "EN12449 & CUNI10FE1MN",
      "DEFSTAND 13-95 & CU-DHP, Gilding metal",
      "DIN 17671 & SF CU",
      "IS 191 & FRTP GRADE 2",
      "JIS 3250, JSS4710 & C110, GRD -I",
      "MIL B 20292A & C 101-130, C220, CUZN10 N220",
      "MIL B 18907 & Gilding metal",
    ],
    image: images.machined_component.image,
    listingImage: images.machined_liting.image,
    spec_one_title: "Product Applications areas",
    // spec_one_desc:
    //   "<ul> <li>> Driving Band Blanks for Defense Applications </li> <li>> Marine Industry </li> <li>> Synchronizer Rings for Automobile </li> <li>> Bushes </li> </ul>",
    spec_one_desc:
      "<ul> <li>> Cannons and Anti Aircraft Guns </li> <li>> Tank and Anti Tank Guns </li> <li>> Naval and Coastal Defence Guns </li> <li>> Field Artillery </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> CuNi5 </li> <li>> Naval Brass </li> <li>> Copper </li> <li>> Manganese Bronze </li> <li>> Gilding Metal (C22000) </li> <li>> Copper Alloy (C12200) </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 10,
    category_id: 3,
    title: "Copper Rods",
    slug: "copper-rods",
    application_area:
      "Electrical conductor, Electrical switchgear, Bus Bar, Electrical contact, Spring clips, Terminals and Connectors",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 10,
        title: "ETP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["BS - C101", "IS - ETP & FRHC OF 191", "ASTM - C11000"],
        image: images.etp_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Automobile Industry</li> <li>> Bearing & Bushing</li> <li>> Electrical application in switch gear industries</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 3,
        category_id: 10,
        title: "FRTP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["IS - CU-FRTP-1", "IS - FRTP-2", "BS - C102"],
        image: images.frtp_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Automobile Industry</li> <li>> Electrical Application</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 4,
        category_id: 10,
        title: "DPA Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASME - C14200", "ASTM - C14200", "BS - C107"],
        image: images.dpa_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Automobile Industry</li> <li>> Bearing & Bushing</li> <li>> General Engeneering Application where Welding and Brazing is Applicable</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 5,
        category_id: 10,
        title: "Tellurium Copper Rods",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C14500", "BS - C109", "DIN - CUTEP"],
        image: images.tellurium_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Automobile Industry</li> <li>> Electrical Precision Machining</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 100 mm"],
      },
    ],
  },
  {
    id: 11,
    category_id: 3,
    title: "Copper Nickel Rods",
    slug: "copper-nickel-rods",
    application_area:
      "	Marine Hardware, Flanges, Oil & Gas Applications, Welding Rings",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_nickel_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 11,
        title: "90/10 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "EEMUA + ASTM - 7060X & C70600",
          "EEMUA + BS - 7060X & CN102",
          "EEMUA + ASTM - 7060X, C70600, C70620",
          "DEF STAN - CN 102",
          "NES - 90/10 CUNI",
          "ASME & ASTM - C70600",
          "ASME - C70600",
          "ASTM - C70600",
          "AES - C70600",
          "MIL - C70600",
          "ASME + EN  - C70600 & CUNI10FE1MN",
          "ASME SB + EN + PED + AD - C70600+CUNI10FE1MN",
          "JIS - C7060T",
          " JIS - C7060TS",
          "BS - CN102",
          "NES - CN 102",
          "IS - CUNI10FE",
          "IS - CUNI10FE1",
          "DIN - CUNI10FE1.6MN",
        ],
        image: images.copper_nickel90_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          // "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> <li>> Oil & Gas Applications </li> <li>> Welding Rings </li> </ul>",
          "<ul> <li>> Defence Application</li> <li>> Ship Building & Repairs</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 3,
        category_id: 11,
        title: "70/30 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "NES - 70/30 COPPER NICKEL",
          "DEF STQN - 70/30 COPPER NICKEL",
          "ASTM - C71500",
          "MIL - C71500",
          "ASME - C71500",
          "EN + AD + PED - CUNI30MN1FE(CW354H)",
          " BS - CN107",
          "IS - CUNI30MN1FE",
          "GOST - CUNI30FE1MN",
        ],
        image: images.copper_nickel70_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Stud Nut Bolt Flanges in Marine Application</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 100 mm"],
      },
    ],
  },
  {
    id: 12,
    category_id: 3,
    title: "Brass Rods",
    slug: "brass-rods",
    application_area:
      "	Condenser Components, Marine hardware, Welding Rods, Rivet, Valves, Piston, Rods.",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brass_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      // {
      //   id: 1,
      //   category_id: 12,
      //   title: "90/10 Brass",
      //   // slug: "",
      //   // description:
      //   //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
      //   standards: [
      //     "BS 2874",
      //     "ASTM B 21",
      //     "B 135",
      //     "B 43",
      //     "DIN 17665",
      //     "JIS H3250",
      //   ],
      //   image: images.brass_rod90.image,
      //   spec_one_title: "Product Applications areas",
      //   spec_one_desc:
      //     "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
      //   spec_two_title: "",
      //   spec_two_desc: "",
      //   spec_three_title: "",
      //   spec_three_desc: "",
      //   have_child: false,
      //   parent_id: 1,
      // },
      {
        id: 2,
        category_id: 12,
        title: "Red Brass 85/15",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C23000"],
        image: images.brass_rod_red.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc: "<ul> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 3,
        category_id: 12,
        title: "70/30 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C26000", "IS - CUZN30AS"],
        image: images.brass_rod70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 4,
        category_id: 12,
        title: "65/35 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C27000"],
        image: images.brass_rod65.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 5,
        category_id: 12,
        title: "63/37 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C27400",
          "ASTM - C27200",
          "ASTM - C27450",
          "ASTM - C27451",
          "BS - CZ108",
          "EN - CUZN37 (CW508L)",
          "IS - CUZN37",
        ],
        image: images.brass_rod63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10mm to 120 mm"],
      },
      {
        id: 6,
        category_id: 12,
        title: "60/40 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C28000",
          "ASTM - C28500 ",
          "EN - CUZN40 (CW509L)",
          "IS - 60/40 BRASS",
        ],
        image: images.brass_rod60.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Sanitry & Plumbing</li> <li>> Electrical Application (Switches, Connectors)</li> <li>> Hardware</li> <li>> Archeticturial Application</li> <li>> Jwellery</li> <li>> Musical Instruments</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 7,
        category_id: 12,
        title: "Naval Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C46400",
          "IS - GRADE 1",
          "BS - CZ112 ",
          "EN - CUZN39SN1 (CW719R)",
        ],
        image: images.brass_rod_naval.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Bearing & Bushing</li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 8,
        category_id: 12,
        title: "80/20 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["EN - CUZN20 (CW503)", "ASTM - C24000", "IS - CUZN20"],
        image: images.brass_rod80.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc: "<ul> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: images.nickelsilver.image,
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 9,
        category_id: 12,
        title: "95/5 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C21000"],
        image: images.brass_rod95.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defence Application</li> <li>> Sanitry & Plumbing</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
      {
        id: 10,
        category_id: 12,
        title: "Admiralty Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [],
        image: images.ad_brass_hollow.image,
        spec_one_title: "",
        spec_one_desc: "",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 10 mm to 120 mm"],
      },
    ],
  },
  {
    id: 13,
    category_id: 3,
    // super_category_id: 1,
    title: "Brass Hollows",
    slug: "brass-hollows",
    application_area:
      "Sugar industry, plumbing, furniture, hand pumps, ammunition, and general engineering ",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brass_hollow_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 1,
        category_id: 13,
        title: "70/30 Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - C26000", "IS - CUZN30AS", "EN - CUZN30AS (CW707R)"],
        image: images.brass_hollow70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Sugar industry </li> <li>> Plumbing </li> <li>> Furniture </li> <li>> Hand pumps </li> <li>> Ammunition </li> <li>> General engineering</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 30 mm to 170 mm", "WT - 4 mm to 12mm"],
      },
      {
        id: 2,
        category_id: 13,
        title: "63/37 Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - C27400",
          "ASTM - C27200",
          "ASTM - C27450",
          "ASTM - C27451",
          "BS - CZ108",
          "EN - CUZN37 (CW508L)",
          "IS - CUZN37",
        ],
        image: images.brass_hollow63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Architectural </li> <li>> Skirting </li> <li>> Fasteners </li> <li>>  Plumbing accessories </li> <li>> Snap buttons </li> <li>> Deep drawn parts</li> <li>> Lamp fixtures </li> <li>> Rivets </li> <li>> Hinges </li> <li>> Flashlight cell's button </li> <li>> Electrical industry component </li> <li>> Zippers </li> <li>> Stamp parts </li> <li>> Eyelet fastners </li> <li>> Connectors </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 30 mm to 170 mm", "WT - 4 mm to 12 mm"],
      },
      {
        id: 3,
        category_id: 13,
        title: "Admiralty Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASME - C44300",
          "ASTM - C44300",
          "DIN - CUZN28SN1",
          "EN - CUZN28SN1AS - (CW706R)",
          "IS - CUZN28SN1AS",
          "BS - CZ111",
        ],
        image: images.ad_brass_hollow.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Evaporator Tubing </li> <li>> Heat exchanger Tubing </li> <li>> Condenser Tubing </li> <li>> Oil Well pump liner </li> <li>> Bourdon tubes etc. </li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: ["OD - 30 mm to 170 mm", "WT - 4 mm to 12 mm"],
      },
    ],
  },
  // {
  //   id: 14,
  //   category_id: 3,
  //   title: "Nickel Silver Rods",
  //   slug: "nickel-silver-rods",
  //   application_area:
  //     "Guards, Pins, nuts, Automobile parts, Springs, Zippers, Musical Instruments, Spectacles, Ammunition",
  //   // description:
  //   //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
  //   standards: [
  //     "ASTM - C74500",
  //     "ASTM - C75200",
  //     "ASTM - C76400",
  //     "ASTM - C75700",
  //     "ASTM - C77000",
  //     "EN - CUNI12ZN24 (CW403J)",
  //     "EN - CUNI12ZN24 (CW409J)",
  //   ],
  //   image: images.nickel_silver_rod.image,
  //   listingImage: images.nickel_silver_liting.image,
  //   spec_one_title: "Product Applications areas",
  //   spec_one_desc:
  //     "<ul> <li>> Automobile Industry</li> <li>> Bearing & Bushing</li> ",
  //   spec_two_title: "",
  //   spec_two_desc: "",
  //   spec_three_title: "Alloy",
  //   spec_three_desc:
  //     "<ul> <li>> N.S.104 </li> <li>> C74500 </li> <li>> N.S.105 </li> <li>> C75200 </li> <li>> N.S.106 </li> <li>> C75200</li> <li>> N.S.107 </li> <li>> C76400 </li> <li>> CW403J </li> <li>> CW409J</li> </ul>",
  //   have_child: false,
  //   parent_id: null,
  //   childs: [],
  //   size: ["OD - 16 mm to 100 mm"],
  // },
  {
    id: 15,
    category_id: 3,
    title: "Aluminium Bronze Rod",
    slug: "aluminium-bronze-rod",
    application_area:
      "High Pressure Valve, Gears, Valve Marine Pumps, Marine Hardware Bearing cages, Bushings",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "AMS - C62300",
      "BS - CA103",
      "BS - CA104",
      "IS - CUAL10FE5NI5",
      "EN - CW307G",
    ],
    image: images.Al_bronze_rod.image,
    listingImage: images.al_bronze_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Defence Application</li> <li>> Automobile Industry</li> <li>> Aerospace Industry</li> <li>> Bearing & Bushing</li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloy",
    spec_three_desc:
      "<ul> <li>> CA 103 </li> <li>> CA 104 </li> <li>> C 63000 </li> </ul>",
    have_child: false,
    parent_id: null,
    childs: [],
    // size: ["OD - 15 mm to 100 mm"],
    size: [
      "OD - 15 mm to 100 mm",
      "Extruded & Drawn Rods - 16 mm to 80 mm Dia",
      "Extruded Rods - 80 mm to 120 mm Dia",
      "Cast and Machined - 120 to 290 mm Dia",
    ],
  },
  {
    id: 16,
    category_id: 3,
    title: "Phosphorus Bronze Rods",
    slug: "phosphorus-bronze-rods",
    application_area:
      "Washers, Fasteners, Switch Parts, Electrical Connectors, Electronic & Precision Equipment Parts, Welding Rods, Springs",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "IS - CUSN5",
      "IS - CUSN8",
      "BS - PB104",
      "BS - PB102",
      "EN - CUSN8 (CW453K)",
      "EN - CUSN5 (CW451K)",
      "ASTM - C51000,C52100",
    ],
    image: images.Phosphorous_bronze_rod.image,
    listingImage: images.phosphorous_bronze_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc: "<ul> <li>> Defence Application</li></ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloy",
    spec_three_desc:
      "<ul> <li>> P.B.4% CuSn4 </li> <li>> P.B.5% CuSn5 </li> <li>> P.B.6% CuSn6 </li> <li>> P.B.8% CuSn8 </li> <li>> P.B.10% </li> </ul>",
    have_child: false,
    parent_id: null,
    childs: [],
    size: ["OD - 20 mm to 100 mm"],
  },
  {
    id: 17,
    category_id: 3,
    // super_category_id: 1,
    title: "Billets",
    slug: "billets",
    application_area: "Extrusion, Forging and Machining industries",
    // description:"",
    // standards:["",""],
    image: images.billets_listing.image,
    listingImage: images.billets_listing.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 1,
        category_id: 13,
        title: "70/30 Copper Nickel",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - B111 & C71500",
          "ASTM - B151 & C71520",
          "MIL-C - 15726 E",
        ],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 2,
        category_id: 13,
        title: "90/10 Copper Nickel",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM - B111 & C70600",
          "ASTM - B151 & C70620",
          "EEMUA - 234 & 7060X",
        ],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 3,
        category_id: 13,
        title: "95/5 Copper Nickel",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - B111 & C70400"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 4,
        category_id: 13,
        title: "DHP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - B187 & C12200"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 5,
        category_id: 13,
        title: "ETP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - B 187 & C11000"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 6,
        category_id: 13,
        title: "Aluminium Bronze",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - B150 & C63000"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 7,
        category_id: 13,
        title: "Nickel Aluminium Bronze",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["BS EN - 1982:2008 & CUAL10FE5NI5-C"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
      {
        id: 8,
        category_id: 13,
        title: "70/30 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM - B927 & C26000"],
        image: images.billets_inner.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Extrusion, Forging and Machining industries </li>  </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        // spec_two_desc: "DYNAMIC",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
        size: [
          "Size range as follows - 120 mm to 290 mm Dia",
          "Temper Condition - Cast & Machined",
        ],
      },
    ],
  },
  {
    id: 18,
    category_id: 3,
    title: "Ingots",
    slug: "ingots",
    application_area: "Ingots",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [],
    image: images.ingots_inner.image,
    listingImage: images.ingots_listing.image,
    spec_one_title: "Product Range",
    spec_one_desc:
      "<ul> <li>> Manufacturing high-quality Copper and Copper Alloy Ingots, meeting diverse Standards and Grades.</li><li>> Weight: 15 to 50 Kgs each</li><li>> Shape: Rectangular</li><li>> Temper Condition: As Cast</li></ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "",
    spec_three_desc: "",
    have_child: false,
    parent_id: null,
    childs: [],
    size: [""],
  },
];

export const findAllProducts = (category_slug = null) => {
  if (category_slug) {
    const category = findCategoryWhere({ slug: category_slug });
    console.log({ category });
    // return _.find(datas, { 'category': category_slug });
    return _.filter(productsArr, { category_id: category[0]?.id });
  } else {
    return productsArr;
  }
};
export const findAllCategories = (super_category_slug = null) => {
  if (super_category_slug) {
    const super_category = findSuperCategoryWhere({
      slug: super_category_slug,
    });
    console.log({ super_category });
    // return _.find(datas, { 'category': category_slug });
    return _.filter(ProductCategoriesArr, {
      super_category_id: super_category[0]?.id,
    });
  } else {
    return ProductCategoriesArr;
  }
};

export const getHeaderProducts = (category_id = null) => {
  const category = findCategoryWhere({ id: category_id });
  // return _.find(datas, { 'category': category_slug });
  return _.filter(productsArr, { category_id: category_id }).map((item) => {
    return {
      title: item.title,
      slug: `/products/${category?.[0]?.slug}/${item.slug}`,
    };
  });
};

export const findProductBySlug = (slug) => {
  return _.find(productsArr, { slug: slug });
};

export const findCategoryBySlug = (value, key = "slug") => {
  return _.find(ProductCategoriesArr, { [key]: value });
};

export const findCategoryWhere = (filter = {}) => {
  return _.filter(ProductCategoriesArr, filter);
};
export const findProductWhere = (filter = {}) => {
  return _.filter(productsArr, filter).map((item) => {
    return {
      ...item,
      category: findCategoryBySlug(item.category_id, "id"),
      super_category: findSuperCategoryBySlug(item.super_category_id, "id"),
    };
  });
};

export const findSuperCategoryBySlug = (value, key = "slug") => {
  return _.find(SuperCategoriesArr, { [key]: value });
};

export const findSuperCategoryWhere = (filter = {}) => {
  return _.filter(SuperCategoriesArr, filter);
};
